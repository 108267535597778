import React from 'react';

const Tile = ({ value, onClick, isSelected }) => {
  const className = `tile ${isSelected ? 'selected' : ''}`;
  return (
    <div className={className} onClick={onClick}>
      {value}
    </div>
  );
};

export default Tile;