import React from 'react';
import Tile from './Tile';

const Board = ({ tiles, onTileClick, selectedTiles }) => {
  return (
    <div className="board">
      {tiles.map((tile, index) => (
        <Tile 
          key={index} 
          value={tile} 
          onClick={() => onTileClick(tile)}
          isSelected={selectedTiles.includes(tile)}
        />
      ))}
    </div>
  );
};

export default Board;